export const zoom = 14;
export 	const center = [487210,5554770];
export 	const extent = [481700,5551500, 494500,5560200];
export 	const logoPath = './logo_maintal.png';
export 	const logoSize = [66,25];
export 	const logoType = 'PNG';
export 	const attributions = ['<a href="https://www.maintal.de/impressum/index.php#content">Impressum</a> | <a href="https://www.maintal.de/datenschutz/index.php#content">Datenschutz</a> '];
export const weg = ['heusenstamm-runde-mit-schloss-und-see-am-goldberg.gpx'];

export const dop = {
    url:    '/geoserver/maintal/wms', 
    layer:  'maintal:DOP_2021',
    serverType: 'geoserver'
};
export const alkis = {
    url:    '/geoserver/maintal/wms', 
    layer:  '',
    serverType: 'geoserver'
};
export const fnp = {
    url:    '/geoserver/maintal/wms', 
    layer:  '',
    serverType: 'geoserver'
};