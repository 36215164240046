import { combineReducers } from 'redux';

import measureType from './measureType';
import printScale from './printScale';
import printSize from './printSize';
import printOrientation from './printOrientation';
import followGPS from './followGPS';
import digiType from './digiType';


let reduce = combineReducers({
    measureType: measureType,
    printScale: printScale,
    printSize: printSize,
    printOrientation: printOrientation,
    followGPS: followGPS,
    digiType: digiType,
})

export default reduce;
