import React  from 'react';
import {
  LayerTree,
  SimpleButton,
} from '@terrestris/react-geo';

import { 
	Card,
  Checkbox, 
	Col, 
	Drawer, 
	Form,
	Radio,
	Row,
  Select  } from 'antd';

import { connect } from 'react-redux';  
import { changemeasureType, changeprintOrientation, changeprintSize, changeprintScale, changefollowGPS, changedigiType } from '../actions/index';


class GgwSettings extends React.Component {

	constructor(props) {
		super(props);

    this.state = {
      visibleSettings: false
    };
    
    this.settings = this.settings.bind(this);
    this.measureState = this.measureState.bind(this);
  }
  
  settings = () => {
    console.log("settings");  
     this.setState(prevState => ({
      visibleSettings: !prevState.visibleSettings
    }));
    
  };

	measureState = (event) => {
		console.log('measureType', event.target.value);
		this.setState({
			measureType: event.target.value,	
		});
	};
  
  layerRenderer = (layer) => {
    console.log("layerRenderer");
    return (<Radio value={layer.get('label')} disabled={!layer.get('digi')}>{layer.get('name')}</Radio>);
    
  }
  
  render() {

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };	

    const { Option } = Select;

    var map = this.props.map;
    var style = this.props.style;
    var tooltipPlacement = this.props.tooltipPlacement;
    
    console.log("GgwSettings render()");
    //console.log(this.props);
    
    return (
      <div>
        <div>
          <SimpleButton
            style={style}
            shape="circle"
            iconName="bars"
            tooltip="Einstellungen"
            tooltipPlacement = {tooltipPlacement}
            onClick={this.settings}
          />
        </div>
        <div>
        <Drawer
          title="Einstellungen"
          className="ggw-settings"
          placement="right"
          onClose={this.settings}
          open={this.state.visibleSettings}
          mask={false}		
          bodyStyle={{
            backgroundColor: "#afafaf"
          }}
        >
        <Form layout="vertical">
          <Row gutter={[8,8]} >
            <Col span={24}>
              <Card title="Ebenen">
                <Radio.Group  onChange={(value) => {this.props.changedigiType(value)}} value={this.props.digiType}>			
                  <LayerTree
                    filterFunction={(layer) =>  !/^react-geo/.test(layer.get('name')) }
                    nodeTitleRenderer={(layer) => this.layerRenderer(layer) }
                    map={map}
                  />
                </Radio.Group>
              </Card>
            </Col>            														
            <Col span={24}>	
              <Card title="Messen">
                <Radio.Group onChange={(value) => {this.props.changemeasureType(value)}} value={this.props.measureType}>
                  <Radio style={radioStyle} value={'line'}>
                    Linie
                  </Radio>
                  <Radio style={radioStyle} value={'polygon'}>
                    Fläche
                  </Radio>
                  <Radio style={radioStyle} value={'angle'}>
                    Winkel
                  </Radio>						
                </Radio.Group>
              </Card>
            </Col>
            <Col span={24}>	
              <Card title="Drucken">
                <Col span={24}>	
                  <Radio.Group onChange={(value) => {this.props.changeprintOrientation(value)}} value={this.props.printOrientation}>
                    <Radio style={radioStyle} value={'portrait'}>
                      Hochformat
                    </Radio>
                    <Radio style={radioStyle} value={'landscape'}>
                      Querformal
                    </Radio>						
                  </Radio.Group>
                  </Col>
                  <Row>
                    <Col span={12}>	
                      <Select onChange={(value) => {this.props.changeprintSize(value)}} defaultValue={this.props.printSize}>
                        <Option value="a0">DIN A0</Option>
                        <Option value="a1">DIN A1</Option>
                        <Option value="a2">DIN A2</Option>
                        <Option value="a3">DIN A3</Option>
                        <Option value="a4">DIN A4</Option>
                        <Option value="a5">DIN A5</Option>
                      </Select>
                    </Col>
                    <Col span={12}>								
                      <Select onChange={(value) => {this.props.changeprintScale(value)}} defaultValue={this.props.printScale}>
                        <Option value="5">1 : 5000 </Option>
                        <Option value="1">1 : 1000 </Option>
                        <Option value="0.75">1 : 750</Option>
                        <Option value="0.5">1 : 500</Option>
                        <Option value="0.25">1 : 250</Option>
                        <Option value="0.1">1 : 100</Option>
                      </Select>								
                    </Col>
                  </Row>
              </Card>
            </Col>
            <Col span={24}>	
              <Card title="GPS">
                <Checkbox checked={this.props.followGPS} onChange={this.props.changefollowGPS}>Folgen</Checkbox>
              </Card>
            </Col>            
          </Row>
        </Form>	
        </Drawer>
        </div>
      </div> 			   
    )
  };
}

let mapStateToProps = (state) => {
  return {
    measureType: state.measureType,
    printOrientation: state.printOrientation,
    printSize: state.printSize,
    printScale: state.printScale,
    followGPS: state.followGPS,
    digiType: state.digiType
  }
}

let mapDispatchToProps = {
  changemeasureType: changemeasureType,
  changeprintOrientation: changeprintOrientation,
  changeprintSize: changeprintSize,
  changeprintScale: changeprintScale,
  changefollowGPS: changefollowGPS,
  changedigiType: changedigiType
}

let GgwSettingsContainer = 
  connect(mapStateToProps, mapDispatchToProps)(GgwSettings);

export default GgwSettingsContainer;
