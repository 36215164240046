let initialState = true;

function followGPS(state = initialState, action) {
    //console.log("followGPS "+ action.type)
    if (action.type === "CHANGE_GPS") {
        console.log ("followGPS " + state);
        return !state;
    } else {
        return state;
    }
}
export default followGPS;