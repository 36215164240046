let initialState = null;

function digiType(state = initialState, action) {
    //console.log("digiType "+ action.type)
    if (action.type === "CHANGE_DIGI") {
        console.log ("digiType " + action.digiType.target.value);
        return action.digiType.target.value;
    } else {
        return state;
    }
}
export default digiType;