let initialState = "a4";

function printSize(state = initialState, action) {
    //console.log("printSize "+ action.type)
    if (action.type === "CHANGE_SIZE") {
        console.log ("printSize " + action.printSize);
        return action.printSize;
    } else {
        return state;
    }
}
export default printSize;