export function changemeasureType(measureType) {
    console.log ("changemeasureType");
    console.log (measureType);
    return {type: "CHANGE_MEASURE", measureType: measureType};
}

export function changeprintOrientation(printOrientation) {
    console.log ("changeprintOrientation");
    console.log (printOrientation);
    return {type: "CHANGE_ORIENTATION", printOrientation: printOrientation};
}

export function changeprintSize(printSize) {
    console.log ("changeprintSize");
    console.log (printSize);
    return {type: "CHANGE_SIZE", printSize: printSize};
}

export function changeprintScale(printScale) {
    console.log ("changeprintScale");
    console.log (printScale);
    return {type: "CHANGE_SCALE", printScale: printScale};
}

export function changefollowGPS(followGPS) {
    console.log ("changefollowGPS");
    console.log (followGPS);
    return {type: "CHANGE_GPS", followGPS: followGPS};
}

export function changedigiType(digiType) {
    console.log ("changedigiType");
    console.log (digiType);
    return {type: "CHANGE_DIGI", digiType: digiType};
}

