let initialState = "line";

function measureType(state = initialState, action) {
    //console.log("measureType "+ action.type)
    if (action.type === "CHANGE_MEASURE") {
        console.log ("measureType " + action.measureType.target.value);
        return action.measureType.target.value;
    } else {
        return state;
    }
}
export default measureType;