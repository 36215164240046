import {getPointResolution} from 'ol/proj';
//Format
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';

import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image-more';

//import { logoPath, logoSize, logoType } from './erlensee';
//import { logoPath, logoSize, logoType } from './freigericht';
import { logoPath, logoSize, logoType } from './maintal';
//import { logoPath, logoSize, logoType } from './obertshausen';
//import { logoPath, logoSize, logoType } from './rodenbach';

const dims = {
	a0: [1189, 841],
	a1: [841, 594],
	a2: [594, 420],
	a3: [420, 297],
	a4: [297, 210],
	a5: [210, 148],
};

let exportOptions = {
	filter: function (element) {
		var className = element.className || '';
		
		if ( className.indexOf('ol-attribution') > -1 ) {
			return true;
		}
		
		return (
			className.indexOf('ol-control') === -1 ||
			className.indexOf('ol-scale') > -1
		);
	},
};

export function printPrev () {
	console.log('PrintPrev');

	this.printSource.clear();

	var l,b;
	if ( this.props.printOrientation === 'portrait'){ l=1; b=0; }
	else{ l=0; b=1; }

	var resolution = 72;
	var scale = this.props.printScale;
	var inch = 25.4;	
	var frame = 4;
	var dim = dims[this.props.printSize];
	var width = Math.round(((dim[l]-(2*frame)) * resolution) / (2*inch));
	var height = Math.round(((dim[b]-(2*frame)) * resolution) / (2*inch));

	var center = this.map.getView().getCenter();

	var scaleResolution =
	  scale /
	  getPointResolution(
		this.map.getView().getProjection(),
		resolution / inch,
		center
	  );
	
	
	  this.printSource.addFeature(new Feature({
		geometry: new Polygon([[[center[0]-(width*scaleResolution),center[1]+(height*scaleResolution)],
		[center[0]+(width*scaleResolution),center[1]+(height*scaleResolution)],
		[center[0]+(width*scaleResolution),center[1]-(height*scaleResolution)],
		[center[0]-(width*scaleResolution),center[1]-(height*scaleResolution)],
		[center[0]-(width*scaleResolution),center[1]+(height*scaleResolution)]]]
		),
	}));
	
	console.log('viewResolution',this.map.getView().getResolution());
	console.log('zoomLelel',this.map.getView().getZoom());		
}

export function print() {
	//console.log('Print');

	this.printSource.clear();
	
	var l,b;
	if ( this.props.printOrientation === 'portrait'){ l=1; b=0; }
	else{ l=0; b=1; }

	var resolution = 72;
	var scale = this.props.printScale;
	var inch = 25.4;
	var frame = 4;
	var dim = dims[this.props.printSize];
	var width = Math.round(((dim[l]-(2*frame)) * resolution) / inch);
	var height = Math.round(((dim[b]-(2*frame)) * resolution) / inch);
	var viewResolution = this.map.getView().getResolution();
	var scaleResolution =
	  scale /
	  getPointResolution(
		this.map.getView().getProjection(),
		resolution / inch,
		this.map.getView().getCenter()
	  );

	//console.log('width',width);
	//console.log('height',height);
	//console.log('viewResolution',viewResolution);
	//console.log('zoomLelel',this.map.getView().getZoom());
	//console.log('scaleResolution',scaleResolution);
	
	this.map.once('rendercomplete', () => {

		exportOptions.width = width;
		exportOptions.height = height;			

		//console.log('rendercomplete');	
		domtoimage
		.toJpeg(this.map.getViewport(), exportOptions)
		.then( (dataUrl) => {


			var pdf = new jsPDF(this.props.printOrientation, undefined, this.props.printSize);
			pdf.addImage(dataUrl, 'JPEG', frame, frame, dim[l]-(2*frame), dim[b]-(2*frame));

			pdf.addImage(logoPath, logoType, dim[l]-(2*frame)-logoSize[0], dim[b]-(2*frame)-(1.5*logoSize[1]), logoSize[0], logoSize[1]); 	 

			pdf.line(frame, frame, dim[l]-frame, frame);
			pdf.line(dim[l]-frame, frame, dim[l]-frame, dim[b]-frame);
			pdf.line(dim[l]-frame, dim[b]-frame, frame, dim[b]-frame);
			pdf.line(frame, dim[b]-frame, frame, frame);

			pdf.save('map.pdf');
			//console.log('Reset original map size',attribution.getProperties());
			this.map.getTargetElement().style.width = '100vw';
			this.map.getTargetElement().style.height = '100vh';
			this.map.updateSize();
			//this.map.setSize(size);
			this.map.getView().setResolution(viewResolution);	
			this.scaleLine.setDpi();
		})
		.catch(error => {
			alert(error)
			this.map.getTargetElement().style.width = '100vw';
			this.map.getTargetElement().style.height = '100vh';
			this.map.updateSize();
			//this.map.setSize(size);
			this.map.getView().setResolution(viewResolution);	
			this.scaleLine.setDpi();
		});			
	});

	//Druck vorbereitung
	this.scaleLine.setDpi(resolution);
	this.map.getTargetElement().style.width = width + 'px';
	this.map.getTargetElement().style.height = height + 'px';		
	this.map.updateSize();
	//this.map.setSize([width, height]);
	this.map.getView().setResolution(scaleResolution);		
}

export function printPrevEnd (){
	console.log('PrintPrevEnd');
	this.printSource.clear();
}