export const zoom = 14;
export const center = [510000,5552750];
export const extent = [504500,5548500, 512500,5557000];
export const logoPath = './logo_freigericht.png';
export const logoSize = [60,15];
export const logoType = 'PNG';
export const attributions = ['<a href="https://www.freigericht.de/impressum/">Impressum</a> | <a href="https://www.freigericht.de/datenschutz/">Datenschutz</a> | '];
export const weg = ['vorderspessartblick.gpx'];

export const dop = {
    url:    '/geoserver/freigericht/wms', 
    layer:  'freigericht:DOP_2018',
    serverType: 'geoserver'
};
export const alkis = {
    url:    '/geoserver/freigericht/wms', 
    layer:  'freigericht:alkis',
    serverType: 'geoserver'
};
export const fnp = {
    url:    '/geoserver/freigericht/wms', 
    layer:  'freigericht:FNP',
    serverType: 'geoserver'
};