let initialState = "portrait";

function printOrientation(state = initialState, action) {
    //console.log("printOrientation "+ action.type)
    if (action.type === "CHANGE_ORIENTATION") {
        console.log ("printOrientation " + action.printOrientation.target.value);
        return action.printOrientation.target.value;
    } else {
        return state;
    }
}
export default printOrientation;