let initialState = "0.5";

function printScale(state = initialState, action) {
    //console.log("printScale "+ action.type)
    if (action.type === "CHANGE_SCALE") {
        console.log ("printScale " + action.printScale);
        return action.printScale;
    } else {
        return state;
    }
}
export default printScale;